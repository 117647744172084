<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		tooltipMsg="pmanualobjectty"
		:showActionDelete="false"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import loading from '@/mixins/loading.mixin';
import { model, fields } from './object-type.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'ObjectType',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailoftheobje');
			const name = this.model.OtypeNameSk || '';
			const id = this.$t('id');
			const idNum = this.model.OtypeId;
			return `${title} ${name} ${id} ${idNum}`;
		},
	},

	methods: {
		async loadResources() {
			this.getCountryEnum();
		},

		getCountryEnum() {
			this.updateSchemaFields({ CountryId: { values: this.$store.state.country.data } });
		},
	},
};
</script>